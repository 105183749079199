<template>
  <v-container>
    <backArrow
      style="margin-left: 12px; margin-top: 5px; position: initial"
      :action="() => onClickFinish()"
    />

    <v-row class="px-3 py-3">
      <v-col cols="12" align-self="start" v-if="paymentStatus && paymentStatus === 'ok'">
        <v-row>
          <v-col cols="12">
            <p class="main-title mb-0 pb-0">Telemedicina</p>
            <p
              style="color: var(--v-primary)"
              class="mt-2 mb-0 font-weight-bold"
            >
              Pronto, você concluiu sua compra!
            </p>
          </v-col>

          <v-col cols="12">
            <p class="text-justify">
              Seus
              <span class="font-weight-bold"
                >dependentes maiores de 18 anos</span
              >
              receberão um link de acesso para download do aplicativo Eu
              Protegido. A utilização do serviço deverá ser feita pelo
              <span class="font-weight-bold">login e senha individual</span>.
            </p>
          </v-col>

          <v-col cols="12">
            <p class="text-justify">
              Para
              <span class="font-weight-bold"
                >dependentes menores de 18 anos</span
              >, o serviço deve ser acionado pelo login do titular e, para isso,
              bastar ir na página inicial, clicar no ícone do Menu e na
              sequência em Telemedicina:
            </p>
          </v-col>

          <v-col cols="12" class="mt-0 pt-0">
            <v-row align="center">
              <v-col cols="4" align="end">
                <v-avatar color="white" size="60px">
                  <v-icon dark color="primary" large> fas fa-bars </v-icon>
                </v-avatar>
              </v-col>

              <v-col cols="2">
                <v-icon color="primary">fas fa-arrow-right</v-icon>
              </v-col>

              <v-col cols="4">
                <v-card rounded="lg" class="item-card" width="auto" height="80">
                  <v-card-text>
                    <v-row style="height: 50px" class="pt-1">
                      <v-col cols="12">
                        <v-row align="center" justify="center">
                          <v-img
                            max-height="50"
                            max-width="50"
                            :src="'/img/icons/menu-nick/icon-megaphone.svg'"
                          />
                        </v-row>
                        <v-row
                          class="mt-2"
                          align="center"
                          justify="center"
                          style="font-size: 12px"
                        >
                          <p
                            class="
                              description-menu
                              text-center
                              font-weight-bold
                            "
                          >
                            Telemedicina
                          </p>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <p class="text-justify">
              Se tiver dúvidas, fale com a nossa equipe através do botão
              <span class="font-weight-bold">Atendimento</span>, acessível
              também pelo Menu.
            </p>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" align-self="start" v-else>
        <v-row>
          <v-col cols="12">
            <p class="main-title mb-0 pb-0">Telemedicina</p>
            <p style="color: var(--v-primary)" class="mt-2 mb-0 font-weight-bold">
              Ops, algo não deu certo!
            </p>
          </v-col>

          <v-col cols="12">
            <p class="text-justify">
              Pagamento não aprovado. Por favor, verifique as informações inseridas ou entre em contato com o emissor do seu cartão.
            </p>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="px-4" align-self="end">
        <generic-btn
          style="width: 100%"
          :btn-props="{ disabled: false }"
          :on-click="() => onClickFinish()"
        >
          Sair
        </generic-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import backArrow from "../../components/covid/back-arrow";
import { mapMutations } from "vuex";
import UserService from "@/services/security/User";

export default {
  name: "CartManagement",
  components: {
    backArrow,
  },
  data: () => ({
    paymentStatus: null,
  }),
  computed: {},
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    async onClickFinish() {
      this.loading(true);
      await this.reloadSessionByToken();
      this.loading(false);
      this.$router.push({ name: "minha-empresa" });
    },
    async reloadSessionByToken() {
      const token = this.$auth.getAccessToken();
      let reloadSessionResponse = await this.userService.reloadSessionByToken(token);
      try {
        if (reloadSessionResponse.data) {
          this.$auth.setUser(reloadSessionResponse.data.user);
        }
      } catch (e) {
        this.showmsg({text: "Erro ao recarregar dados da sessão.", type: "error"});
        this.loading(false);
      }
    },
  },
  async mounted() {
    this.userService = new UserService();
    if(this.$route.params && this.$route.params.paymentStatus) {
      this.paymentStatus = this.$route.params.paymentStatus;
    }
  },
};
</script>

<style>
a {
  font-weight: bold;
  text-decoration: none;
  color: var(--v-primary) !important;
}

.item-card {
  border-radius: 11px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(24px) !important;
}
</style>
